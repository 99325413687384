.Classroom-container{
    display: flex;
    width: 100%;
justify-content: center;
align-items: center;
}
.Image3-container{
    display: flex;
    justify-content: space-around;
   width: 100%;
}
.Image3>img{
    width: 100%;
    height: 100%;
    border-radius: 33px;
    object-fit: cover;
 opacity: 0.6;
}
.play-video{
   width: 100%;
height: 185px;
border-top-right-radius:8rem;
border-bottom-left-radius:8rem;
border-bottom-right-radius:8rem;
background-color: black;
position: relative;
}
.Image3{
    height:556px;
    width: 480px;
    border-radius: 33px;
    background-color: black;
}

.play-video-container{
    position:absolute;
  top: 40px;
  left: 50px;
    color: white;
    
}
.play-video-content1{
    font-family: Hind Vadodara;
font-size: 40px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: center;

}
.content3-container-data-header{
font-family: Gilda Display;
font-size: 40px;
font-weight: 400;
line-height: 56px;
letter-spacing: 0em;
text-align: left;

}
.content3-container-data{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.content3-container-data-para{
font-family: Hind Vadodara;
font-size: 20px;
font-weight: 500;
line-height: 30px;
letter-spacing: 0em;
text-align: justified;

}
.play-video-content2{
    font-family: Hind Vadodara;
font-size: 20px;
font-weight: 500;
line-height: 40px;
letter-spacing: 0em;
text-align: center;

}
.play-circle{
    border-radius: 50%;
    height:163px;
    width: 163px;
    
   overflow: hidden;
    background-color: #3D9970E5;
    cursor: pointer;
    top: 12px;
    right: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: white;
    position: absolute;
}

.play-video>img{
    width: 100%;
    height: 100%;
    border-top-right-radius:8rem;
border-bottom-left-radius:8rem;
border-bottom-right-radius:8rem;
opacity: 0.5;
    object-fit: cover;
}
.content3-container{
   display: flex;
   flex-direction: column;
   gap: 20px;
   width: 575px;
   color: #001F3F;
   
}
