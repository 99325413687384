.gtco-testimonials {
    position: relative;
    margin-top: 30px;
  }
  
  .gtco-testimonials h1 {
   
    text-align: center;
    color:#001F3F;
    margin-bottom: 20px;
    font-family: Yantramanav;
font-size: 56px;
font-weight: 700;
line-height: 125px;
letter-spacing: 0em;
  }
  
  .gtco-testimonials .owl-stage-outer {
    padding: 30px 0;
  }
  
  .gtco-testimonials .owl-nav {
    display: none;
  }
  
  .gtco-testimonials .owl-dots {
    text-align: center;
  }
  
  .gtco-testimonials .owl-dots span {
    position: relative;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: block;
    background: #3d9970;
 
    margin: 0 5px;
  }
  
  .gtco-testimonials .owl-dots .active {
    box-shadow: none;
  }
  .owl-dot>span{
    background-color: #3d9970 !important;
  }
  .gtco-testimonials .owl-dots .active span {
    
    box-shadow: none;
    height: 12px;
    width: 12px;
    margin-bottom: -1px;
  }
  
  .gtco-testimonials .card {
    background: #fff;
    box-shadow: 0 8px 30px -7px #c9dff0;
    margin: 0 20px;
    padding: 0 10px;
    border-radius: 20px;
    border: 0;
  }
  
  .gtco-testimonials .card .card-img-top {
    max-width: 100px;
    border-radius: 50%;
    margin: 15px auto 0;
    box-shadow: 0 8px 20px -4px #95abbb;
    width: 100px;
    height: 100px;
  }
  
  .gtco-testimonials .card h5 {
    color: #3d9970;
    font-size: 21px;
    line-height: 1.3;
  }
  
  .gtco-testimonials .card h5 span {
    font-size: 18px;
    color: #666666;
  }
  
  .gtco-testimonials .card p {
    font-size: 18px;
    color: black;
    padding-bottom: 15px;
  }
  
  .gtco-testimonials .active {
    opacity: 0.5;
    transition: all 0.3s;
  }
  
  .gtco-testimonials .center {
    opacity: 1;
  }
  
  .gtco-testimonials .center h5 {
    font-size: 24px;
  }
  
  .gtco-testimonials .center h5 span {
    font-size: 20px;
  }
  
  .gtco-testimonials .center .card-img-top {
    max-width: 100%;
    height: 120px;
    width: 120px;
  }
  
  @media (max-width: 767px) {
    .gtco-testimonials {
      margin-top: 20px;
    }
  }
  
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel button.owl-dot {
    outline: 0;
  }
  