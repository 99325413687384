.Contact-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 50px;
    position: relative;
    padding-left: 80px;
    padding-top: 35px;
}
.yellow-line{
    position: absolute;
    right: 250px;
    z-index: -10;
}
.background-contact{
    
    height: 100%;
    position: absolute;
    right: 0px;
    z-index: -10;

}
.background-contact>img{
    width: 100%;
    height: 100%;
    
    
    object-fit: contain;
}
/* .Contact-form {
    
    height:auto ;
    position: relative ;
 
} */
.mobile-contact{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.form-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
}
.form-input-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
input[type=submit]{
display: flex;
justify-content: center;
align-items: center;
width: 120px;
height: 45px;
gap: 0px;
border: 3px solid #2A5B1E;
color: #2A5B1E;
background-color: white;
font-family: Hind Vadodara;
font-size: 18px;
font-weight: 400;
line-height: 27.6px;
text-align: center;

}
.form-input{
    width: auto;
    color: black;
    font-family: Hind Vadodara;
    font-weight: 300;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid black;
    padding: 10px;
}
.form-input::placeholder{
    color: black;
    font-family: Hind Vadodara;
    font-weight: 300;
    font-size: 16px;
}
option{
    color: black;
    font-family: Hind Vadodara;
    font-weight: 300;
    font-size: 16px;
}
.form-header-title{
    
    font-family: Hind Vadodara;
    font-size: 54px;
    font-weight: 700;
    line-height: 99.33px;
    text-align: left;
    color: #2A5B1E;

    

}
.hello{
    font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0.01em;
text-align: left;

}

.form-header-container{
    display: flex;
    flex-direction: column;
}

.Contact-form{

  
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.detail-conatiner{
    display: flex;
    justify-content: space-between;
   width: 100%;
   align-items: center; 
}

.option{
    font-family: Montserrat;
font-size: 14px;
font-weight: 400;
line-height: 24px;
letter-spacing: 0.01em;
text-align: left;
padding:10px;
}
/* #select-dropdown{
    width: 99% !important;
} */
/* .phone-content-header{
    font-family: Montserrat;
font-size: 13px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.02em;
text-align: left;



}
.phone-content{
    font-family: Montserrat;
font-size: 13px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.02em;
text-align: left;
color: #3D9970;
}

.map{
    top: 65px;
    right: -200px;
    width: 400px;
    height: 75vh;
    position: absolute;
} */