
.Enroll-page{
  padding-top: 91.6px;
  padding-left: 20px;
  /* width: 100%; */
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  /* background-color: rgb(250, 246, 241); */
}
.Enroll-heading{
  font-family: Yantramanav;
    font-size: 40px;
  /* background-color: rgb(250, 246, 241); */

}
.fieldset{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  /* background-color: rgb(250, 246, 241); */
}
.form-row-container{
  display: flex;
 
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  align-items: center;
}
.form-container-en{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
legend{
  font-weight: bold;
  font-size: 20px;
  color: #777;
}
.form-control{
  border-radius: 0;
  box-shadow: none;
  height: 36px;
  display: block;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    /* background-color: rgb(250, 246, 241); */
    background-image: none;
    border: 1px solid #ccc;
}
#amount{
  background-color: #ccc;
}
.ReactModal__Overlay{
  z-index: 1000 !important;
}
.upi{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.upi-content{
  font-family: Yantramanav;
  font-size: 20px;
  font-weight: 600;

}
.ReactModal__Overlay{
  background-color: rgb(81 77 77 / 75%) !important;
}
.ReactModal__Content{
  height: fit-content;
}
.bank-detail-title{
  font-size: 25px;
  font-weight: 500;
}
.bank-detail-container{
  display: flex;
  gap:5px;
}
.bank-content{
  font-size: 18px;
}
.bank-header{
  font-size: 18px;
  font-weight: 600;
}
.bank-detail{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.modal-enroll{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}
.bank-close{
  display: flex;
justify-content: center;
align-items: center;
width: 120px;
height: 45px;
gap: 0px;
border: 3px solid #2A5B1E;
color: #2A5B1E;
background-color: white;
font-family: Hind Vadodara;
font-size: 18px;
font-weight: 400;
line-height: 27.6px;
text-align: center;
}
.centerLine{
  width: 90%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.533);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.centerLine>span{
  position:absolute;
  font-size: 25px;
  background-color: white;
  color: rgba(0, 0, 0, 0.533);
}
.form-group{
  display: flex;
  gap: 10px;
  width: 100%;
  font-family: Yantramanav;
  flex-direction: column;
  font-weight: bold;
  font-size: large;
}
/* .Enroll-heading::after{
  content: "[No registration Fee]";
  animation: blinker 1s linear infinite;
    color: #93120d;
    font-size: 20px;
} */
@keyframes blinker {
  50%{
    opacity: 0;
  }
}
@media only screen and (max-width: 800px) {
  /* .form-container{
    width: 90vw !important;
  }
  .Enroll-page{
    width: 89.5% !important;
  }
  /* .dropdown{
    position: static !important;
    background-color: white !important;
  }
  a:hover{
    color: black !important;
    
  }
  a{
    color: #3d9970 !important;
  } */

  .hamburger1{
    display: -ms-grid !important;
    display: grid !important;
    margin-right: 30px !important;
  }
  .ReactModal__Content{
    inset: 0px !important;
  }
 .Enroll-heading{
  font-size: 35px;
 }
 .Enroll-heading::after{
  font-size: 12px;
 }
  .menu{
    display: none !important;
  }
  .hide{
    display: none !important;
  }
  /* .hamburger1{
    display: -ms-grid !important;
    display: grid !important;
    margin-right: 30px !important;
  }
  .menu{
    display: none !important;
  }
  .hide{
    display: none !important;
  }
  .Image-container2{
      display: none;
  }
  .content3-container{
      width: 80vw !important;
   
  }
  .play-video-content1{
      font-size: 20px !important;
  }
  .play-video-content2{
      font-size: 10px !important;
  }
  .play-video-container{
      top: 15px !important;
  left: 15px !important;
  }
  .play-video-container1{
      top: 15px !important;
      right: 15px !important;
  }
  .play-video,.play-video1{
      height: 135px !important;
  }
  .play-circle1{
      height: 125px !important;
      width: 125px !important;
      top: 5px !important;
     left: 5px !important; 
  }
  .play-circle{
      height: 125px !important;
      width: 125px !important;
      top: 5px !important;
      right: 5px !important;
  }
  .Image3{
      display: none;
  }
  .top{
      flex-direction: column-reverse;
  }
  .Top-footer{
      left: 0px !important;
      line-height: 30px !important;
      font-size: 20px !important;
      padding: 10px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 0px !important;
  }
  .play-icon{
      width: 40px !important;
      height: 40px !important;
  }
  .Image-container1{
      height:210px;
      width: 100% !important;
  }
  .para{
      width: 100% !important;
      margin: 0px 0px 20px 0px;
      font-size: 12px;
  }
  .point-Image{
      display: none !important;
  }
  .blog-heading{
      font-size: 35px !important;
      line-height: 50px !important;
  }
  .blogs-header {
      flex-direction: column;
      gap: 10px;
  }
 .About{
  flex-direction: column;
  height: auto !important;
 }
.About-img{
  width: 100% !important;
  height: 275px;
}
.About-content{
  width: auto !important;
  height: fit-content !important;
  padding: 10px;
  gap: 10px !important;
}
.Hero-video{
  width: 100% !important;
}
.About-content-content{
  line-height: normal !important;
 

  word-spacing: normal !important;
}
  .para-container {
   left: 0px !important;
      padding: 10px;
     width: 95% !important;
  }
  .Hero-header{
      
      width: 100% !important;
  }
  .hero{
      /* height: 90vh !important; */
      /* display: flex;
      flex-direction: column-reverse;
      padding-top: 82.5px !important;
  }
  .Hero-header-container{
      height: auto !important;
  }
  .Hero-header-content{
      font-size:40px !important;
     line-height: 45px !important;
     padding: 10px;
   
  }
.Enroll-heading{
  font-size: 35px;
}
.Enroll-heading::after{
  font-size: 15px ;
} */
.Hero-header-content{
  width: fit-content !important;
}
.rect-container{
  display: none;
}
.About-content-container{
  padding-top: 20px !important;
 gap:25px !important;
 padding-left:20px !important;
 padding: 20px;
 padding-bottom:20px !important;
 height:90%;
 flex-direction: column-reverse;
}
.Feature-section{
flex-direction: column;
}
.testimonial-container{
  height: auto !important;
  padding: 20px;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  gap: 30px;
}
.mySwiper{
  padding-top: 0px !important;
  padding-left: 0px !important;
}
.yellow-line{
  display: none !important;
}
.background-contact{
  display: none !important;
}
.Contact-container{
  padding-top: 0px !important;
  padding-left: 0px !important;
  width: 100%;
}
.Hero-header-content{
  margin-left: 0px !important;
}
.logo{
  padding-left: 20px !important;
}
.en{
  margin-left: 20px !important;
}
.mobile-contact{
width: 100%;
}
.Contact-form{
  width: 100%;
}
.blogs-container-header {
  height: auto !important;
}
.blogs-container-header-title{
  width: 100% !important;
}
.blogs-container-header-content{
  width: 100% !important;
}
.blogs-carousal-container{
  width: 90vw !important;
}
.svg-arrow{
  bottom:20px !important;
}
.Hero-header-content {
  font-size: 40px !important;
  line-height: 50px !important;
}
.blogs-container{
  height: auto !important;
  flex-direction: column;
  padding-left: 20px !important;
  padding-top: 20px !important;
  gap: 30px;
  padding: 20px;
  padding-bottom: 20px !important;
}
.about-right{
  width: 100% !important;
  height: auto !important;
  /* align-self: flex-start;
  padding-left: 10px; */
}
.about-left{
  width: 100% !important;
  /* align-self: flex-start;
  padding-left: 10px; */
}
.form-container{
  width: 100% !important;
}
.logo-container{
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Footer{
  padding-left: 20px !important;
}
.testimonial-header {
  padding-left: 0px !important;
  line-height: 65px !important;
}
.tempbox{
width: fit-content !important;
}
}
