.point-container{
    display: flex;
    width: 100%;
    height: auto;
}
.point-Image>img{
    width: 550px;
    height: 100%;
    object-fit: cover;
}
.point-content-container{
    background: #3D9970;
      height:auto;
      width: 100%;
}
.point-content-container{
    padding:0px 20px 0px 20px;
}

.point-content-header{
font-family: Yantramanav;
font-size: 50px;
font-weight: 700;
letter-spacing: 0em;
text-align: left;
color: white;
}
.point-box{
    width: 50px;
height: 50px;
border-radius: 8px;
background: #FFD700;

margin: 10px;
}
.points-content-header{
    font-family: Gilda Display;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
     
}
.point-box-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.points-content-content{
font-family: Hind Vadodara;
font-size: 15px;
font-weight: 500;

letter-spacing: 0em;
text-align: left;

}
.points-content-container{
    width: 90%;
    color: white;
    padding: 10px;
}
.points{
    display: flex;
}