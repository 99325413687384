/* Google Fonts - Poppins */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");


.testimonial-container {
 /* width: 100%; */
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  padding-top: 50px;
  /* height: 90vh; */
  padding-bottom: 50px;
}
.testimonial-carousal{
  display: flex;
  justify-content: center;
  align-items: center;
}
.mySwiper{
padding-left: 80px;
padding-top: 40px;
height: 375px !important;
}

.swiper-pagination-bullet-active {
  background-color: #2A5B1E !important;
}
.tempbox{
  width: 300.76px;
height: 272.19px;
padding: 22.37px 0px 0px 0px;
gap: 11.18px;
border-radius: 3.73px 0px 0px 0px;
border: 0.93px 0px 0px 0px;
background: #F4F4F4;
border: 0.93px solid #E7EAEC;
padding: 20px;
display: flex;
flex-direction: column;
position: relative;
}
.tempbox-name{
  font-family: Manrope;
font-size: 20.5px;
font-weight: 600;
line-height: 28.01px;
letter-spacing: 0.02em;

}
.watchButton{
  border: none;
  color: white;
  background-color: #0275d8;
  border-radius: 2rem;
  font-family: Montserrat;
 cursor: pointer;
  padding: 10px;
}
.tempbox-content{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10; /* Number of lines to show before ellipsis */
  max-height: calc(1.5em * 10); /* Number of lines * line height */
  text-overflow: ellipsis;
  font-family: Manrope;
font-size: 13px;
font-weight: 400;
line-height: 20.37px;
letter-spacing: 0.02em;
text-align: left;

}
.stars{
  top: 8px;
  right: 0px;
  position: absolute;
}
.img-container{
  width: 100%;
  position: relative;
}
.testimonial-header{
  font-family: Hind Vadodara;
font-size: 64px;
font-weight: 700;
line-height: 99.33px;
text-align: left;
color: #2A5B1E;
padding-left: 80px;
}
/* .mySwiper{
    width: 60%;
 
}
.testimonial {
  position: relative;
  max-width: 900px;
  width: 95%;
  padding-top: 15px;
  padding-bottom: 30px;
  /* padding: 30px 0; */
  /* overflow: hidden;
  } */
  /* .testimonial .image {
    height: 170px;
    width: 170px;
    object-fit: cover;
    border-radius: 50%;
  }
  .testimonial .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
    height: 100%;
    width: 100%;
  }
  .slide p {
    text-align: center;
    padding: 0 65px;
    font-size: 14px;
    font-weight: 400;
    font-family: Hind Vadodara;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;


    color: white;
  }
  .slide .quote-icon {
    font-size: 30px;
    color: #4070f4;
  }
  .slide .details {
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  .details .name {
    font-family: Hind Vadodara;
    font-size: 16px;
    padding: 10px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: white;
  }
  .details .job {
    font-family: Hind Vadodara;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0em;
text-align: center;
color: white;
  }
  .swiper-button-prev {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transform: translateY(30px);
    
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }

  .swiper-button-prev::after,
  .swiper-button-prev::before {
    font-size: 20px;
    font-weight: 800;
 color: white;
  }
.swiper-button-next {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    transform: translateY(30px);
    
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s;
  }
.heading{
    color: white;
    font-family: Yantramanav;
    font-size: 40px;
padding-top: 10px;
line-height: 90px;
letter-spacing: 0em;
text-align: center;

}
  .swiper-button-next::after,
  .swiper-button-next::before {
    font-size: 20px;
    font-weight: 800;
    color: white;
  }
  .swiper-slide{
    height:auto;
    position: relative;
  }
  .black-box>img{
    width: 100%;
    height: 100%;
    border-radius: 33px;
    object-fit: cover;
 opacity: 0.4;
  }
  .yellow-circle3{
    left: -30px;
    background-color: rgba(255, 255, 0, 0.444);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 65px;
    position: absolute;

}
.yellow-circle4{
    right: -20px;
    background-color: rgba(255, 255, 0, 0.444);
    border-radius: 50%;
    width: 100%;
    height: 100%;
    top: 65px;
    position: absolute;
}
  .black-box-circle{
    border-radius: 50%;
    height: 107px;
    width: 107px;
    background: #3D9970E5;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: white;
    position: absolute;
    transform: translate(-50%,-50%);
  }
  .black-box{
    width: 275px;
height: 200px;
border-radius: 33px;
position: relative;

background-color: black;
  }  */