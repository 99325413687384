.About{
    position: relative;
   
    /* height: 90vh; */
   /* padding-bottom: 15px; */ 
   display: flex;
   justify-content: center;
   /* align-items: center; */
}
.About {

  overflow: hidden; /* Hide overflowing parts of the images */
}

.rect-container {
  position: absolute;
  top: 0;
  z-index: -10;
  right: 0;
  width: 100%; /* Ensure the container spans the full width */
  height: 100%; /* Ensure the container spans the full height */
}

.rect-container img {
  position: absolute;
  top: 0;
  height: 100%; /* Each image spans the full height */
  object-fit: cover; /* Ensure the images cover the container */
}
.About-content{
    width: 50%;

    background-color: rgb(224, 238, 201);
    display: flex;
    justify-content: center;
   align-items: center;
    flex-direction: column;
    gap: 20px;

}
.About-content-container{
  display: flex;
  /* justify-content: space-evenly; */
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding-top: 50px;
  gap: 12vw;
  align-items: center;
  padding-left: 80px;
  padding-bottom: 50px;
}
.about-left{
  width: 415px;
top: 913px;
left: 120px;
gap: 0px;
display: flex;
flex-direction: column;
gap: 20px;
}
.about-left-point{
  font-family: Hind Vadodara;
font-size: 16px;
font-weight: 400;
line-height: 20.8px;
text-align: left;

}
.about-left-subcontent{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.about-left-content{
  font-family: Hind Vadodara;
font-size: 16px;
font-weight: 400;
line-height: 20.8px;
text-align: left;


}
.about-left-header{
  font-family: Hind Vadodara;
font-size: 48px;
font-weight: 700;
line-height: 60.34px;
color: #2A5B1E;

text-align: left;

}
.about-right{
  width: 584px;
height: 377px;
top: 918px;
left: 736px;
gap: 0px;
}
.test{
    opacity: 0;
    transition: opacity 1s ease-in-out;
    position: absolute;
  }
  .visible {
    opacity: 1;
  }
  
  .hidden {
    opacity: 0;
  }
  
.About-content-header{
    font-family: Yantramanav;
    font-size: 40px;
    width: 80%;
    color: darkolivegreen;
}
.About-content-content{
    width: 80%;
    font-family: Yantramanav;
    font-size: 20px;
    line-height: 30px;
    word-spacing: 10px;
    color: darkolivegreen;
}
.About-img{
    width: 50%;
 position: relative;

}
.About-img>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}